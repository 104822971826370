/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import 'bootstrap-icons/font/bootstrap-icons.scss'
import 'react-data-grid/lib/styles.css'

import * as Sentry from '@sentry/react'
import axios from 'axios'
import { ru } from 'date-fns/locale'
import { setDefaultOptions } from 'date-fns/setDefaultOptions'
import { pdfjs } from 'react-pdf'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import './lib/i18next'

dayjs.extend(duration)
setDefaultOptions({ locale: ru })

// import { route as routeFn } from 'ziggy-js'
//
// declare global {
//   const route: typeof routeFn
// }

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

const dsn = import.meta.env.VITE_SENTRY_DSN_PUBLIC
const appEnv = import.meta.env.MODE

// @ts-expect-error tmp
window.viteEnvs = {
  dsn,
  appEnv,
}

Sentry.init({
  environment: appEnv,
  dsn,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

// @ts-expect-error tmp
window.Sentry = Sentry

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.withCredentials = true
window.axios.defaults.withXSRFToken = true

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });
